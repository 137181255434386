



































































































import Vue from 'vue';
import { mapMutations, mapGetters } from 'vuex';
import { ProjectTree } from '@/interface/product';
import { replaceDeList, unitSameList } from '@/api/projectDetail';
import { setTableHeaderWidth } from '@/utils';

const columns = [
  {
    title: '序号',
    dataIndex: 'dispNo',
    key: 'dispNo',
    align: 'center',
    width: 80,
  },
  {
    title: '项目编码',
    dataIndex: 'fxCode',
    scopedSlots: { customRender: 'fxCode' },
    key: 'fxCode',
    align: 'center',
    width: 150,
  },
  {
    title: '名称',
    dataIndex: 'fxName',
    key: 'fxName',
    align: 'center',
  },
  {
    title: '项目特征',
    dataIndex: 'projectAttr',
    scopedSlots: { customRender: 'projectAttr' },
    key: 'projectAttr',
    width: '220px',
    align: 'left',
  },
  {
    title: '单位',
    dataIndex: 'unit',
    scopedSlots: { customRender: 'unit' },
    key: 'unit',
    align: 'center',
  },
  {
    title: '综合单价(元)',
    dataIndex: 'price',
    scopedSlots: { customRender: 'price' },
    key: 'price',
    align: 'center',
  },
];

export default Vue.extend({
  name: 'index',
  props: {
    visible: {
      type: Boolean,
    },
    record: {
      type: Object,
    },
    type: {
      type: Number,
    },
  },
  data() {
    const projectTree: ProjectTree[] = [];
    const selectedKeys: string[] = [];
    const expandedKeys: string[] = [];
    const checkedItem: string[] = [];
    const replaceExpandedKeys: string[] = [];
    return {
      projectTree,
      selectedKeys,
      expandedKeys,
      replaceExpandedKeys,
      columns,
      data: [],
      allTableList: [], // 所有单位工程的清单项
      spId: '', // 单项工程ID
      unitId: '', // 单位工程ID
      tableHeadHeight: 0,
      targetBillId: 0, // 目标清单ID
      checkedItem, // 右侧选中的项集合
      loading: false, // 渲染前加载
      isMergeLoading: false, // 替换并重新计价loading
    };
  },
  computed: {
    ...mapGetters([
      'refreshTreeFrom',
      'currentTreeInfo',
      'projectSequenceNbr',
      'sequenceNbr',
      'projectBidSequenceNbr',
    ]),
    dialogScroll() {
      const num: number = 500 - this.tableHeadHeight;
      return { y: num };
    },
    rowSelection() {
      return {
        type: 'radio',
        onChange: (selectedRowKeys, selectedRows) => {
          this.targetBillId = selectedRows ? selectedRows[0].sequenceNbr : 0;
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            'selectedRows: ',
            selectedRows
          );
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.kind === '03', // Column configuration not to be checked
            name: record.name,
          },
        }),
      };
    },
  },
  created() {
    this.setDialogHeight();
  },
  mounted() {
    this.unitSameList();
    window.addEventListener('resize', this.getTableHeaderWidth);
  },
  methods: {
    ...mapMutations([
      'SET_RefreshTree',
      'SET_PRICE_ADJUSTMENT_VALUATION',
      'SET_IS_THERE_REPLACE',
    ]),
    getContainer() {
      return document.getElementById('ysf_body');
    },
    getTableHeaderWidth() {
      setTableHeaderWidth('.replace-list');
    },
    noHandle() {
      this.$emit('update:visible', false);
    },
    setDialogHeight() {
      this.$nextTick(() => {
        const tableEl = document.querySelector(
          '.ant-table-fixed'
        ) as HTMLElement;
        let tableHeadHeight = 0;
        if (tableEl) {
          tableHeadHeight = tableEl.clientHeight;
        }
        this.tableHeadHeight = tableHeadHeight;
      });
    },
    selectNode(
      selectedKeys: string[],
      { node }: { node: { dataRef: Record<string, string>; expanded: boolean } }
    ) {
      if (Number(node.dataRef.type) === 3) {
        this.unitId = node.dataRef.id;
        this.spId = '';
      } else if (Number(node.dataRef.type) === 2) {
        this.spId = node.dataRef.id;
        this.unitId = '';
      } else {
        this.spId = '';
        this.unitId = '';
      }
      this.unitSameList();
    },

    checkNode(
      checkedKeys: string[],
      { node }: { node: { dataRef: Record<string, string>; checked: boolean } }
    ) {
      if (!node.checked) {
        if (node.dataRef.children) {
          this.getLastChildData(node.dataRef);
        } else {
          this.checkedItem.push(node.dataRef.id);
        }
      } else {
        if (node.dataRef.children) {
          this.checkedItem = [];
        } else {
          this.checkedItem = this.checkedItem.filter((key: string) => {
            return key !== node.dataRef.id;
          });
        }
      }
    },
    getLastChildData(treeInfo) {
      treeInfo.children.forEach((item) => {
        item.children && this.getLastChildData(item);
        if (!item.children) {
          this.checkedItem.push(item.id);
        }
        return;
      });
    },
    unitSameList() {
      this.loading = true;
      let apiData = {
        fxCode: this.record.fxCode,
        fxName: this.record.fxName,
        unit: this.record.unit,
        projectAttr: this.record.projectAttr,
        itemType: this.type === 1 ? 'fbfx' : 'djcs',
        constructId: this.projectBidSequenceNbr,
        unitId: this.unitId,
        spId: this.spId,
      };
      unitSameList(apiData).then((res: any) => {
        console.log('res', res);
        if (res.status === 200 && res.result) {
          this.loading = false;
          this.data = res.result.itemList;
          if (!this.unitId && !this.spId) {
            this.projectTree = res.result.unitList;
            const rootTreeInfo = res.result.unitList[0];
            const rootKey = rootTreeInfo.id;
            this.selectedKeys = [rootKey];
            this.setExpandedKeys(this.projectTree);
            this.getTableHeaderWidth();
          }
        }
      });
    },
    setExpandedKeys(data) {
      for (let i = 0, len = data.length; i < len; i++) {
        let item = data[i];
        console.log(item.id);
        this.expandedKeys.splice(this.expandedKeys.length, 0, item.id);
        this.replaceExpandedKeys.splice(
          this.replaceExpandedKeys.length,
          0,
          item.id
        );
        if (item.children && item.children.length) {
          this.setExpandedKeys(item.children);
        }
      }
    },
    replaceDeList(isMerge) {
      if (!this.targetBillId) {
        this.$message.error('请选择要替换的清单');
        return;
      }
      if (this.checkedItem.length === 0) {
        this.$message.error('请选择替换综合单价到工程');
        return;
      }
      this.isMergeLoading = isMerge;
      this.loading = true;
      let apiData = {
        targetBillId: this.targetBillId,
        selectUnitIds: this.checkedItem.toString(),
        itemType: this.type === 1 ? 'fbfx' : 'djcs',
        isMerge: isMerge,
      };
      replaceDeList(apiData).then((res: any) => {
        console.log('res', res);
        if (res.status === 200 && res.result) {
          this.loading = false;
          this.$message.success('替换成功');
          if (isMerge) {
            this.SET_PRICE_ADJUSTMENT_VALUATION(true);
          } else {
            this.SET_IS_THERE_REPLACE(true);
          }
          this.$emit('update:visible', false);
          this.$emit('replaceFinish', true);
        } else {
          this.$message.error('替换失败');
        }
      });
    },
    rowClassName(record: any) {
      let className = 'parent-show';
      if (record.kind === '03') className = 'child-show';
      return className;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getTableHeaderWidth, false);
  },
});
